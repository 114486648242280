import React from 'react';
import _ from 'lodash';
import { getLocalizedText } from './common/localizationManager';

//const Notifications = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
//const Scheduler = React.lazy(() => import('./views/Routes/Management/Scheduler/Scheduler'));
const AuthTemplates = React.lazy(() => import('./views/Routes/Management/AuthTemplates/AuthTemplates'));
const Dashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/Dashboard'));
const Tenant = React.lazy(() => import('./views/Routes/Management/Tenant/Tenant'));
const Company_Mng = React.lazy(() => import('./views/Routes/Management/Companies/Company'));
const Users_Mng = React.lazy(() => import('./views/Routes/Management/Users/Users'));
const ResourceDefinition_Mng = React.lazy(() => import('./views/Routes/Management/ResourceDefinition/ResourceDefinition'));
const Parameters_Mng = React.lazy(() => import('./views/Routes/Management/Parameters/Parameters'));
const Kibana = React.lazy(() => import('./views/Routes/Management/Kibana/Kibana'));
const Department = React.lazy(() => import('./views/Routes/Management/Department/Department'));
const CommunicationDefinitions_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationDefinitions/CommunicationDefinitions'));
const CommunicationTemplates_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationTemplates/CommunicationTemplates'));
const Notifications_Mng = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
const LivePreview = React.lazy(() => import('./views/Routes/LivePreview/LivePreview'));
const TransactionLogs_Mng = React.lazy(() => import('./views/Routes/Management/TransactionLogs/TransactionLogs'));
const ChangePassword = React.lazy(() => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'));
const Brand = React.lazy(() => import('./views/Routes/ECommerce/Brand/Brand'));
const Campaign = React.lazy(() => import('./views/Routes/ECommerce/Campaign/Campaign'));
const Category = React.lazy(() => import('./views/Routes/ECommerce/Category/CategoryTree'));
const OrderItem = React.lazy(() => import('./views/Routes/ECommerce/OrderItem/OrderItem'));
const OrderPayment = React.lazy(() => import('./views/Routes/ECommerce/OrderPayment/OrderPayment'));
const Order = React.lazy(() => import('./views/Routes/ECommerce/Order/Order'));
const Price = React.lazy(() => import('./views/Routes/ECommerce/Price/Price'));
const Product = React.lazy(() => import('./views/Routes/ECommerce/Product/Product'));
const ProductVariant = React.lazy(() => import('./views/Routes/ECommerce/ProductVariant/ProductVariant'));
const Variant = React.lazy(() => import('./views/Routes/ECommerce/Variant/Variant'));
const Installment = React.lazy(() => import('./views/Routes/ECommerce/Installment/Installment'));
const PaymentChannel = React.lazy(() => import('./views/Routes/ECommerce/PaymentChannel/PaymentChannel'));
const ProductUsageLog = React.lazy(() => import('./views/Routes/ECommerce/ProductUsageLog/ProductUsageLog'));
const UsedCampaign = React.lazy(() => import('./views/Routes/ECommerce/UsedCampaign/UsedCampaign'));
const Customer = React.lazy(() => import('./views/Routes/ECommerce/Customer/Customer'));
const ApiCredential = React.lazy(() => import('./views/Routes/ApiCredential/ApiCredential'));
const Asset = React.lazy(() => import('./views/Routes/Asset/Asset'));
const AssetBalance = React.lazy(() => import('./views/Routes/AssetBalance/AssetBalance'));

var PageInfos = {
    Dashboard: {
        name: getLocalizedText("DASHBOARD"),
        url: `/dashboard`,
        component: Dashboard,
        resourceCode: 'MainPage',
        icon: 'icon-speedometer',
    },
    Title_Admin: {
        title: true,
        name: getLocalizedText("TITLE_ADMIN"),
    },
    Menu_Admin: {
        name: getLocalizedText("ADMINISTRATION"),
        resourceCode: 'AdminPages',
        icon: 'cui-cog',
        defaultOpen: true
    },
    Tenant: {
        name: getLocalizedText('TENANTS'),
        url: '/tenant',
        component: Tenant,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Tenant_Res',
        icon: 'icon-book-open'
    },
    Company_Mng: {
        name: getLocalizedText("COMPANIES"),
        url: '/company',
        component: Company_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreCompany_Res',
        icon: 'icon-grid',
    },
    Department: {
        name: getLocalizedText("DEPARTMENTS_TITLE"),
        url: '/department',
        component: Department,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreDepartment_Res',
        icon: 'icon-puzzle',
    },
    AuthTemplate_Mng: {
        name: getLocalizedText("AUTH_TEMPLATES"),
        url: '/authTemplates',
        component: AuthTemplates,
        parentResourceCode: 'AdminPages',
        resourceCode: 'AuthTemplates',
        icon: 'icon-docs',
    },
    Users_Mng: {
        name: getLocalizedText("USERS"),
        url: '/users',
        component: Users_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Users',
        icon: 'icon-people',
    },
    ResourceDefinition_Mng: {
        name: getLocalizedText("RESOURCE_DEFINITION"),
        url: '/resource-definitions',
        component: ResourceDefinition_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'ResourceDefinition',
        icon: 'icon-layers',
    },
    Parameters_Mng: {
        name: getLocalizedText("PARAMETERS"),
        url: '/parameters',
        component: Parameters_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'SystemParameters',
        icon: 'icon-list',
    },
    Menu_Communications: {
        name: getLocalizedText('COMMUNICATION_SETTINGS_LABEL'),
        resourceCode: 'Communication',
        parentResourceCode: 'AdminPages',
        icon: 'icon-feed',
    },
    CommunicationDefinitions_Mng: {
        name: getLocalizedText('COMMUNICATION_DEFINITIONS'),
        url: '/communicationDefinitions',
        component: CommunicationDefinitions_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationDefinitions_tab',
        icon: 'icon-note',
    },
    CommunicationTemplates_Mng: {
        name: getLocalizedText('COMMUNICATION_TEMPLATES'),
        url: '/communicationTemplates',
        component: CommunicationTemplates_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationTemplates_tab',
        icon: 'icon-envelope',
    },
    SearchMonitor_Mng: {
        name: getLocalizedText('SEARCH_MONITOR_LABEL'),
        url: '/kibana',
        component: Kibana,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreSearchMonitor_Res',
        icon: 'icon-magnifier',
    },
    AuditLogs_Mng: {
        name: getLocalizedText('AUDIT_LOGS'),
        url: '/transactionLogs',
        component: TransactionLogs_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'TransactionLogs_Res',
        icon: 'icon-book-open',
    },
    Notifications: {
        name: getLocalizedText("NOTIFICATIONS"),
        url: '/notifications/:id?',
        component: Notifications_Mng,
        resourceCode: 'Notifications',
        parentResourceCode: 'AdminPages',
        icon: 'icon-badge',
        showInSidebar: false,
    },
    LivePreview: {
        name: getLocalizedText("LIVE_PREVIEW_LABEL"),
        url: '/livePreview',
        component: LivePreview,
        resourceCode: 'TestResource',
        icon: 'icon-eye',
        badge: { variant: 'info', text: 'NEW' }
    },
    ChangePassword: {
        name: getLocalizedText('CHANGE_PASSWORD_LABEL'),
        url: '/changePassword',
        component: ChangePassword,
        resourceCode: 'ChangePassword_Res',
        showInSidebar: false,
    },
    Menu_ECommerce: {
        name: getLocalizedText("ECOM_TITLE"),
        resourceCode: 'ECommercePages',
        icon: 'icon-plus',
        defaultOpen: true
    },
    Brand: {
        name: getLocalizedText('ECOM_BRAND'),
        url: '/brand',
        component: Brand,
        resourceCode: 'Brand',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-star',
    },
    Campaign: {
        name: getLocalizedText('ECOM_CAMPAIGN'),
        url: '/campaign',
        component: Campaign,
        resourceCode: 'Campaign',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-gift',
    },
    Customer: {
        name: getLocalizedText('ECOM_CUSTOMER'),
        url: '/customer',
        component: Customer,
        resourceCode: 'Customer',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-user',
    },
    Category: {
        name: getLocalizedText('ECOM_CATEGORY'),
        url: '/category',
        component: Category,
        resourceCode: 'Category',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-list',
    },
    Product: {
        name: getLocalizedText('ECOM_PRODUCT'),
        url: '/product',
        component: Product,
        resourceCode: 'Product',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-dropbox',
    },
    Variant: {
        name: getLocalizedText('ECOM_VARIANT'),
        url: '/variant',
        component: Variant,
        resourceCode: 'Variant',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-tag',
    },
    ProductVariant: {
        name: getLocalizedText('ECOM_PRODUCT_VARIANT'),
        url: '/productVariant',
        component: ProductVariant,
        resourceCode: 'ProductVariant',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-shuffle',
    },
    Price: {
        name: getLocalizedText('ECOM_PRICE'),
        url: '/price',
        component: Price,
        resourceCode: 'Price',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-money',
    },
    Order: {
        name: getLocalizedText('ECOM_ORDER'),
        url: '/order',
        component: Order,
        resourceCode: 'Order',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-shopping-cart',
    },
/*     OrderItem: {
        name: getLocalizedText('ECOM_ORDER_ITEM'),
        url: '/orderItem',
        component: OrderItem,
        resourceCode: 'OrderItem',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-cart-plus',
    },
    OrderPayment: {
        name: getLocalizedText('ECOM_ORDER_PAYMENT'),
        url: '/orderPayment',
        component: OrderPayment,
        resourceCode: 'OrderPayment',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-credit-card',
    }, */
    Installment: {
        name: getLocalizedText('ECOM_INSTALLMENT'),
        url: '/installment',
        component: Installment,
        resourceCode: 'Installment',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-calendar-plus-o',
    },
    PaymentChannel: {
        name: getLocalizedText('ECOM_PAYMENT_CHANNEL'),
        url: '/paymentChannel',
        component: PaymentChannel,
        resourceCode: 'PaymentChannel',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-layers',
    },
    ProductUsageLog: {
        name: getLocalizedText('ECOM_PRODUCT_USAGE_LOG'),
        url: '/productUsageLog',
        component: ProductUsageLog,
        resourceCode: 'ProductUsageLog',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-chart',
    },
    UsedCampaign: {
        name: getLocalizedText('ECOM_USED_CAMPAIGN'),
        url: '/usedCampaign',
        component: UsedCampaign,
        resourceCode: 'ProductUsageLog',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-present',
    },    ApiCredential: {
        name: getLocalizedText('Api Credential'),
        url: '/apiCredential',
        component: ApiCredential,
        resourceCode: 'ApiCredential_Res',
        icon: 'icon-energy',
    },
    Asset: {
        name: getLocalizedText('Asset'),
        url: '/asset',
        component: Asset,
        resourceCode: 'Asset_Res',
        icon: 'icon-energy',
    },
    AssetBalance: {
        name: getLocalizedText('Asset Balance'),
        url: '/assetBalance',
        component: AssetBalance,
        resourceCode: 'AssetBalance_Res',
        icon: 'icon-energy',
    }
};

// Scheduler: {
//     name: "Scheduler",
//     url: '/scheduler',
//     component: Scheduler,
//     resourceCode: '',
//     icon: 'icon-clock',
// },

export var Pages = function () {
    for (var page in PageInfos) {
        PageInfos[page].key = page;

        if (PageInfos[page].url && PageInfos[page].path == null)
            PageInfos[page].path = `${PageInfos[page].url}`;
    }
    // let sortedPagesByName = Object.values(PageInfos).sort((a, b) =>
    // (a.name === 'Dashboard' || b.name === 'Dashboard') || a.name.localeCompare(b.name));
    return PageInfos;
}();

export var PagesAsNav = function () {
    var pages = _.values(_.cloneDeep(Pages)).filter(page => page.showInSidebar !== false);
    var toBeDeleted = [];
    for (var page of pages) {
        if (page.defaultOpen) {
            delete page.defaultOpen
            if (!page.url)
                page.url = '';
        }
        if (page.parentResourceCode) {

            var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
            if (foundPage) {
                if (foundPage.children == null)
                    foundPage.children = [];

                foundPage.children.push(page);
                toBeDeleted.push(pages.findIndex(x => x.key == page.key));
            }
        }
    }

    toBeDeleted.sort((a, b) => a - b);
    toBeDeleted.reverse();

    for (var ind of toBeDeleted) {
        pages.splice(ind, 1);
    }
    return pages;
}();

export var PagesAsRoute = function () {
    var pages = _.values(_.cloneDeep(Pages));

    for (var page of pages) {
        if (page.url && page.parentResourceCode == null)
            page.exact = true;
    }
    return pages;
}();